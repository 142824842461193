import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { alpha, useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import Link from "@mui/material/Link";

import ThemeModeToggler from "components/ThemeModeToggler";
import { NavItem } from "./components";

import { company } from "constants/company";

const { REACT_APP_HTTP_SCHEME, REACT_APP_WWW_HOST } = process.env;

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const { menu: menuPages } = pages;

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={1}
    >
      <Box
        display={"flex"}
        component={RouterLink}
        to={"/"}
        title={company.name}
        width={{ xs: 100, md: 120 }}
      >
        <Box
          component={"img"}
          src={
            mode === "light" && !colorInvert
              ? `${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/logos/32_120_light.svg`
              : `${REACT_APP_HTTP_SCHEME}://${REACT_APP_WWW_HOST}/images/logos/32_120_dark.svg`
          }
          height={1}
          width={1}
        />
      </Box>
      <Box sx={{ display: { xs: "none", md: "flex" } }} alignItems={"center"}>
        <Box marginLeft={4}>
          <NavItem
            title={"Menu"}
            id={"menu-pages"}
            items={menuPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <Link
            underline={"none"}
            component={"a"}
            href={"https://documentation.eatabit.io"}
            target={"_blank"}
            color={colorInvert ? "common.white" : "text.primary"}
          >
            Docs
          </Link>
        </Box>
        <Box marginLeft={4}>
          <Link
            underline={"none"}
            component={"a"}
            href={"/terms"}
            color={colorInvert ? "common.white" : "text.primary"}
          >
            Terms
          </Link>
        </Box>
        <Box marginLeft={4}>
          <ThemeModeToggler />
        </Box>
        <Box marginLeft={4}>
          <Button
            variant={"contained"}
            color={"primary"}
            component={RouterLink}
            to={"/sign-in?intent=buy&source=eatabit"}
            size={"large"}
          >
            Buy now
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }} alignItems={"center"}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={"outlined"}
          sx={{
            borderRadius: 2,
            minWidth: "auto",
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
