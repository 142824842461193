import React from "react";
import {
  IndexView,
  NotFound,
  PasswordRecover,
  PasswordReset,
  UserAccount,
  UserCart,
  UserCartComplete,
  UserClient,
  UserCredentials,
  UserJob,
  UserJobs,
  UserPrinter,
  UserPrinters,
  UserTicketCreate,
  SignIn,
  SignUp,
  Terms,
} from "views";

const routes = [
  {
    path: "/",
    renderer: (params = {}) => <IndexView {...params} />,
  },
  {
    path: "/sign-in",
    renderer: (params = {}) => <SignIn {...params} />,
  },
  {
    path: "/sign-out",
    renderer: (params = {}) => <SignIn {...params} />,
  },
  {
    path: "/sign-up",
    renderer: (params = {}) => <SignUp {...params} />,
  },
  {
    path: "/password-recover",
    renderer: (params = {}) => <PasswordRecover {...params} />,
  },
  {
    path: "/password-reset/:token",
    renderer: (params = {}) => <PasswordReset {...params} />,
  },
  {
    path: "/terms",
    renderer: (params = {}) => <Terms {...params} />,
  },
  {
    path: "/support",
    renderer: (params = {}) => <SignIn {...params} />,
  },
  {
    path: "/user/printers",
    renderer: (params = {}) => <UserPrinters {...params} />,
  },
  {
    path: "/user/printers/:id",
    renderer: (params = {}) => <UserPrinter {...params} />,
  },
  {
    path: "/user",
    renderer: (params = {}) => <UserJobs {...params} />,
  },
  {
    path: "/user/jobs",
    renderer: (params = {}) => <UserJobs {...params} />,
  },
  {
    path: "/user/jobs/:id",
    renderer: (params = {}) => <UserJob {...params} />,
  },
  {
    path: "/user/client",
    renderer: (params = {}) => <UserClient {...params} />,
  },
  {
    path: "/user/account",
    renderer: (params = {}) => <UserAccount {...params} />,
  },
  {
    path: "/user/credentials",
    renderer: (params = {}) => <UserCredentials {...params} />,
  },
  {
    path: "/user/support",
    renderer: (params = {}) => <UserTicketCreate {...params} />,
  },
  {
    path: "/user/cart",
    renderer: (params = {}) => <UserCart {...params} />,
  },
  {
    path: "/user/cart-complete",
    renderer: (params = {}) => <UserCartComplete {...params} />,
  },
  {
    path: "*",
    renderer: (params = {}) => <NotFound {...params} />,
  },
];

export default routes;
